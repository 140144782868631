<template>
	<nav>
		<img class="img_nav" src="https://web.inmotech.co/wp-content/uploads/2024/07/Recurso-2.png" alt="">

		<div class="menu-hamburger" id="menu-hamburger">
			<span></span>
			<span></span>
			<span></span>
		</div>

		<div class="container_nav_links">

				<router-link  v-for="(item, i) in links" :key="i" class="menu-link" :to="item.hash ? `${item.path}${item.hash}` : item.path">{{ item.text
					}}</router-link>					
		

		</div>

		<!-- <div class="container_contact_nav">
			<div class="contact_info">
				<i class="material-icons-outlined">phone_in_talk</i>
				<p>(604)4442949</p>
			</div>
			<div class="user_login">
				<a class="btn-icon-login-register">
					<i class="material-icons-outlined">account_circle</i>
				</a>
			</div>
			<div class="nav_btn_inmotech">
				<a href="">Inmotech</a>
			</div>
		</div> -->
	</nav>


</template>

<script>
import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
export default {
	name: 'MainHeader',
	mixins: [mixinConfiguracion],
	data() {
		return {
			menu: false,
			links: this.$router.options.routes
		}
	},
	methods: {
		menuMobile() {
			this.menu = !this.menu;
		}
	}
}
</script>

<style lang="css" scoped>
/* #MainHeader {
	position: sticky;
	top: 0;
	z-index: 99;
	background-color: #75bae9;
} */

/* General */
nav {
	background-color: #152733;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0;
	margin: 0;
	position: relative;
}

.img_nav {
	width: 18%;
	margin: 0.6% 0 0.6% 2%;
}

a {
	text-decoration: none;
}

/* Links del navbar */
.container_nav_links {
        width: 82%;
        display: inline-block;
        font-size: 13px;
        font-weight: 600;
    }

    .container_nav_links>a {
        color: white;
        margin-left: 3%;
    }

    .container_nav_links>a:hover {
        color: #c7932e;
    }


.menu-link {
	color: white;
	margin-left: 3%;
	text-decoration: none;
}

.menu-link:hover {
	color: #c7932e;
}

/* Sección contacto */
.container_contact_nav {
	width: 20%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #c7932e;
	font-size: 13px;
	padding: 10px;
}

.contact_info {
	display: flex;
	align-items: center;
}

.contact_info i {
	font-size: 18px;
}

.contact_info>p {
	font-weight: 600;
	margin-left: 3%;
}

.nav_btn_inmotech {
	padding: 12px 15px;
	background-color: #c7932e;
	border-radius: 4px;
}

.nav_btn_inmotech>a {
	color: #fff;
	font-weight: 600;
}

/* Estilo del menú hamburguesa */
.menu-hamburger {
	display: none;
	flex-direction: column;
	justify-content: space-between;
	width: 30px;
	height: 20px;
	cursor: pointer;
}

.menu-hamburger span {
	background: #fff;
	height: 3px;
	width: 100%;
	border-radius: 2px;
}

/* Responsivo */
@media (max-width: 1213px) {

	.container_nav_links,
	.container_contact_nav {
		display: none;
	}

	.menu-hamburger {
		display: flex;
		margin-right: 20px;
	}

	.container_nav_links {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 60px;
		right: 0;
		background-color: #152733;
		width: 100%;
		align-items: center;
		padding: 10px 0;
		display: none;
	}

	.container_nav_links a {
		margin: 10px 0;
		font-size: 16px;
		color: #fff;
	}

	.container_nav_links a:hover {
		color: #c7932e;
	}

	.container_nav_links.active {
		display: flex;
	}

	.img_nav {
		margin: 10px auto;
		width: 30%;
	}
}
</style>