<template>

	<section class="section-footer">
		<svg xmlns="http://www.w3.org/2000/svg" class="wave" viewBox="0 0 1000 100" preserveAspectRatio="none">
			<path class="elementor-shape-fill" d="M790.5,93.1c-59.3-5.3-116.8-18-192.6-50c-29.6-12.7-76.9-31-100.5-35.9c-23.6-4.9-52.6-7.8-75.5-5.3
        c-10.2,1.1-22.6,1.4-50.1,7.4c-27.2,6.3-58.2,16.6-79.4,24.7c-41.3,15.9-94.9,21.9-134,22.6C72,58.2,0,25.8,0,25.8V100h1000V65.3
        c0,0-51.5,19.4-106.2,25.7C839.5,97,814.1,95.2,790.5,93.1z"></path>
		</svg>
		<footer>



			<div class="grid_footer_first">
				<div>
					<p class="title_footer">
						Apartamento en Arriendo
					</p>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium perferendis praesentium
						placeat
						eum,
						maiores nesciunt qui pariatur hic illo quibusdam quidem sapiente adipisci possimus voluptates
						temporibus
						repellat eligendi. Hic, aut.
					</p>
				</div>
				<div>
					<p class="title_footer">Apartamento en Venta</p>
					<p>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius voluptates accusantium qui
						repudiandae
						repellat quibusdam perferendis praesentium molestiae quasi iure quam, voluptate, reiciendis
						molestias
						cupiditate soluta, exercitationem temporibus ipsa iste.
					</p>
				</div>
				<div>
					<p class="title_footer">Casa en Arriendo</p>
					<p>
						Lorem, ipsum dolor sit amet consectetur adipisicing elit. Accusamus labore necessitatibus
						commodi
						voluptatibus. Ullam officiis ex ipsa architecto aliquid, sit nemo quidem, facilis omnis,
						laboriosam
						quibusdam earum repudiandae molestiae adipisci!
					</p>
				</div>
				<div>
					<p class="title_footer">Casa en Venta</p>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipisicing elit. Esse rerum assumenda sapiente repellat
						praesentium aut facere deleniti, quod expedita aspernatur officiis accusantium nesciunt
						consequatur
						reprehenderit nostrum reiciendis dignissimos. Eius, hic.
					</p>
				</div>

			</div>
			<div class="grid_footer_second">
				<div>
					<p class="title_footer">
						Contáctanos
					</p>
					<p>
					<ul>
						<li> Sede Laureles: Carrera 81 A # 42 B 34</li>
						<li>Medellín, Antioquia</li>
						<li>(604) 444 2949</li>
						<li>+57 315 835 6668</li>
						<li>pqrs@grupoempresarialmi.com</li>
					</ul>

					</p>
				</div>
				<div>
					<p class="title_footer">Información Legal</p>
					<p>
					<ul>
						<li> M.A.V.U N.49/22</li>
						<li>Términos y condiciones</li>
						<li>Política de Privacidad</li>
						<li>Preguntas frecuentes</li>
						<li>Reportar un Pago o Daño</li>
						<li>PQRS</li>
					</ul>
					</p>
				</div>
				<div>
					<p class="title_footer">Redes Sociales</p>
					<p>
					<ul>
						<li>Facebook</li>
						<li>Instagram</li>
						<li>WhatsApp</li>
						<li>TikTok</li>
						<li>LinkedIn</li>
					</ul>
					</p>
				</div>
				<div>
					<p class="title_footer">Pagos PSE</p>
					<p>
						Pago con Tarjeta de Crédito
					</p>
					<img style="width: 120px;" src="/img/pse.png" alt="">
				</div>

			</div>
		</footer>
	</section>
</template>

<script>
import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
import mixinInmuebles from '@/libs/mixins/mixinInmuebles'
export default {

	name: 'MainFooter',
	mixins: [mixinConfiguracion, mixinInmuebles],
	data() {
		return {
			'año': new Date().getFullYear()
		}
	},
	mounted() {
		this.$store.dispatch('AppInmuebles/getListInmuebles');
	}
}
</script>

<style lang="css" scoped>
footer {
	background-color: #152733;
	color: white;
	margin-top: -25px;
}

.grid_footer_first,
.grid_footer_second {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 20px;
	font-size: 18px;
	margin: 20px 20px;
}

.title_footer {
	font-size: 22px;
	font-weight: 700;
}

.wave {
	width: 100%;
	height: 80px;
}

.elementor-shape-fill {
	fill: #152733;
}


.section-footer {
	margin-top: 100px
}

li {
	list-style: none;
}
</style>