import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=4ca5e4a8&scoped=true&"
var script = {}
import style0 from "./AboutUs.vue?vue&type=style&index=0&id=4ca5e4a8&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca5e4a8",
  null
  
)

export default component.exports