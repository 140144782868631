import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init(); // Inicializa AOS aquí

import { BootstrapVue } from 'bootstrap-vue'
import { routes } from '@/router/'
import Vuex from 'vuex'
import App from './App.vue'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ResizeSensor from "resize-sensor"
window.ResizeSensor = ResizeSensor

// Rutas
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to) {
    if(to.hash){
      return{
        selector: to.hash
      }
    }else{
      return{
        y:0
      }
    }
  },
  routes
})

Vue.filter('precio', (value) => {
  let precio = parseInt(value),
      divisa = Intl.NumberFormat("es-CO");
      precio = divisa.format(precio);
  return `$ ${precio} COP`;
});

Vue.filter('clearTag', (value) => {
  let str = value.replace( /(<([^>]+)>)/ig, '');
  str = str.replace(/&nbsp;/ig,' ');
  return str
});

Vue.filter('lowerCase', (value) => {
  let str = value.toLowerCase();
  return str
});

Vue.filter('urlImages', (value, cloud, transform) => {
  if(['', null, undefined].indexOf(value) === -1){
    let url = value.split('/');
    url = url.pop();
    url = url.split('.');
    url.pop();
    url = url.join('.');

    let urlFolder = ['default/', 'inmuebles/', 'users/', 'portales/', 'blogs/', 'empresas/', 'mercadeo/'],
        folder = '';
    for(let item of urlFolder){
      if(value.indexOf(item) != -1){
        folder = item;
      }
    }
    return `https://res.cloudinary.com/${cloud}/image/upload/${transform + folder + url}`;
  }
});

Vue.config.productionTip = false

// Bootstrap
Vue.use(BootstrapVue)

// VueSweetalert2
Vue.use(VueSweetalert2);

// VueMeta
Vue.use(VueMeta)

// VueX
Vue.use(Vuex)

// Google Maps Api
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA1Z8H7n597indjgO41lEeQmF2oEzMrxko',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
