import Home from '@/components/view/Home'
import AboutUs from '@/components/view/AboutUs'
import PQRS from '@/components/view/PQRS'
import InmueblesView from '@/components/view/inmueble/InmueblesView'
import InmueblesStore from '@/components/view/inmueble/InmueblesStore'
import InmuebleDetail from '@/components/view/inmueble/InmuebleDetail'
// import BlogsView from '@/components/view/blog/BlogsView'
// import BlogArchive from '@/components/view/blog/BlogArchive'
// import BlogDetail from '@/components/view/blog/BlogDetail'
import WorkTeam from '../components/view/workTeam.vue'
import Contactanos from '../components/view/Contactanos.vue'
import Postularse from '../components/view/Postularse.vue'
import Abogados from '../components/view/Abogados.vue'
import Preguntas from '../components/view/Preguntas.vue'

const routes = [
    {
      path: '/',
      name: 'inicio',
      text: 'Inicio',
      component: Home
    },
    {
      path: '/inmuebles/',
      text: 'Inmuebles',
      name: 'inmuebles',
      redirect: { name: 'catalogo-inmuebles' },
      component: InmueblesView,
      children:[
        {
          path: 'catalogo/',
          name: 'catalogo-inmuebles',
          component: InmueblesStore
        },
        {
          path: 'detalle/:slug',
          name: 'inmuebles-detalle',
          component: InmuebleDetail
        }
      ]
    },
    // {
    //   path: '/blog/',
    //   name: 'blog',
    //   text: 'Blog',
    //   redirect: { name: 'blog-articulos' },
    //   component: BlogsView,
    //   children:[
    //     {
    //       path: 'articulos/',
    //       name: 'blog-articulos',
    //       component: BlogArchive
    //     },
    //     {
    //       path: 'detalle/:slug',
    //       name: 'blog-detalle',
    //       component: BlogDetail
    //     }
    //   ]
    // },
    
    {
      path: '/sobre-nosotros/',
      name: 'sobre-nosotros',
      text: 'Sobre nosotros',
      component: AboutUs
    },

    {
      path: '/abogados-consultores/',
      name: 'abogados-consultores',
      text: 'Abogados',
      component: Abogados
    },
    {
      path: '/preguntas-frecuentes/',
      name: 'preguntas-frecuentes',
      text: 'Preguntas Frecuentes',
      component: Preguntas
    },
    {
      path: '/pqrs/',
      name: 'pqrs',
      text: 'PQRS',
      component: PQRS
    },
    {
      path: '/contactanos/',
      name: 'contactanos',
      text: 'Contactanos',
      component: Contactanos
    },
    {
      path: '/equipo-trabajo/',
      name: 'equipo-trabajo',
      text: 'Equipo de Trabajo',
      component: WorkTeam
    },
    {
      path: '/postularse/',
      name: 'postularse',
      text: 'Postularse',
      component: Postularse
    },
  ]
export {routes}